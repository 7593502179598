.floatingContainer {
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  width: 95%;
  height: 15%;
  max-height: 80%;
  overflow: hidden;
  background-color: #222222;
  box-shadow: 2 8px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 8px;
  z-index: 1000;
  opacity: 80%;
  border-style: ridge;
}

.bottomFloatingContainer {
  position: fixed;
  bottom: 20px;
  left: 0px;
  right: 0px;
  width: 100%;
  overflow: hidden;
  z-index: 1000;
}

#transparentLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

#transparentLayer>img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.textarea-100 {
  top: 0px;
  width: 100%;
  height: 120px;
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.app-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(5);
  z-index: 3000;
}

.container {
  background-color: transparent;
  height: 100%;
  display: 'flex';
  flex-direction: 'column';
  overflow: 'hidden'
}

.content {
  flex: 1;
  height: 80%;
  overflow-y: 'auto';
  scrollbar-width: thin;
  margin: 0;
  padding: 0
}

/* Dark theme */
:root[data-theme='dark'] {
  --scrollbar-thumb-color: #0e0e0e;
  --scrollbar-track-color: #000000;
  --background-color: transparent;
  background-color: transparent;
  background: transparent;
}

/* Dark theme */
:root_x[data-theme='webDarkTheme'] {
  --scrollbar-thumb-color: #0e0e0e;
  --scrollbar-track-color: #000000;
  --background-color: transparent;
  background-color: transparent;
  background: transparent;
}

/* Custom scrollbar for dark theme */
::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 6px;
  /* Rounded corners of the thumb */
  border: 3px solid #2e2e2e;
  /* Creates padding around the thumb */
}

/* Optional: Style for hover state */
::-webkit-scrollbar-thumb:hover {
  background-color: #666;
  /* Darker shade for hover state */
}

.paper {
  background-image: url(img/paper.png);
  background-repeat: repeat;
}

.fullscreen {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: inset;
  border-width: 1px;
  resize: none;
  background: transparent;
}

#chatbox {
  box-shadow: #222222;
  height: 70%;
  max-height: 70vph;
  overflow-y: scroll;
  vertical-align: top;
  resize: none;
  background: transparent
}

#chatroomStack {
  overflow: hidden;
  width: 100%;
  height: 95%;
  background: rgba(0, 0, 0, 0.7) radial-gradient(circle, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 80%);
  background-blend-mode: darken;
}

.editordialog {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}

#backgroundLayer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: none;
  filter: grayscale(100%);
  opacity: '0.5';
  z-index: -1;
}

#inputTab {
  height: 20%;
  max-height: 80vph;
  overflow: hidden;
  vertical-align: bottom;
  resize: none;
  border-style: none;
  box-shadow: 4 8px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  opacity: 90%;
}

#inputTab2 {
  height: 20%;
  max-height: 80vph;
  overflow: hidden;
  vertical-align: bottom;
  resize: none;

  background: linear-gradient(to bottom, #222222, #2a282c);
  box-shadow: 4 8px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
  opacity: 80%;
  border-color: #181819;
  border-width: 0px;
}

/* Set height of divider to 1px and width to 100% to create a horizontal line */
#divider {
  height: 2px;
  width: 100%;
  background-color: black;
}

.bubble {
  white-space: pre-wrap;
  border-radius: 8px;
  font-family: 'Consolas', 'Courier New', monospace;
  font-size: 1.2rem;
  line-height: 1;
  margin: 0 auto 2px;
  max-width: 2048px;
  padding: 4px;
  position: relative;
}

.bubble-small {
  white-space: pre-wrap;
  border-radius: 0px;
  font-family: 'Consolas', 'Courier New', monospace;
  font-size: 1rem;
  line-height: 1;
  margin: 0 auto 2px;
  max-width: 2048px;
  padding: 2px;
  position: relative;
}

.menubar {
  position: fixed;
  background: #181819;
  padding: 0;
  margin: 0;
  border-style: outset;
  border-width: 1px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-block-color: black;
  filter: grayscale(40%) brightness(120%);
  z-index: 900;
}

.dialogtitle {
  padding: 0;
  margin: 0.5em;
  font-size: 1.5rem;
  width: 100%;
  border-style: none;
  text-align: center;
  position: relative;
}

.dialogsurface {
  position: fixed;
  /* Use fixed positioning to make it hover above other elements */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  min-width: max-content;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  /* Adjust position to truly center the element */
  align-content: center;
  max-width: 100vpw;
  max-height: 100vph;
  margin: 0 auto;
  padding: 0;
  z-index: 1000;
  /* Ensure it sits above other elements */
  border: 4px solid ridged;
  border-radius: 4px;
  /* Rounded corners */
  overflow: hidden;
}

.dialogbody {
  display: inline-block;
  width: fit-content 120%;
  height: fit-content 120%;
  align-self: center;
  max-width: 100vpw;
  max-height: 100vph;
  margin: 0 auto;
  padding: 0;
  border: 4px solid transparent;
}

.dialogcontentEditor {
  overflow: hidden;
  max-width: 100vpw;
  max-height: 100vph;
  width: fit-content 100%;
  height: fit-content 100%;
  align-self: center;
  align-items: center;
}

.dialogcontent {
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
}

.dialogcontent ::-webkit-scrollbar {
  width: 12px;
  /* Width of the vertical scrollbar */
  scroll-behavior: smooth;
  /* Smooth scrolling behavior */
}

.dialogcontent ::-webkit-scrollbar-track {
  background: transparent;
  /* Makes the track transparent */
}

.dialogcontent ::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar thumb */
  border-radius: 6px;
  /* Rounded corners of the scrollbar thumb */
}

.dialogcontent ::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar thumb when hovered */
}

/* Apply styles directly to the textarea element */
textarea.dark-theme {
  color: white;
  /* White text color */
  background-color: #333;
  /* Dark background */
  font-size: 1.25rem;
  /* 1.25 times the base font size */
  font-family: Consolas, 'Courier New', monospace;
  border-radius: 10px;
  /* Rounded corners */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* Dark shadow */
  border: none;
  /* Removes the default border */
  padding: 10px;
  /* Adds some padding inside the textarea */
  width: calc(100% - 20px);
  /* Adjusts width to account for padding */
  box-sizing: border-box;
  /* Ensures padding doesn't add to the total width */
  resize: none;
}

/* Optionally, add a focus style */
textarea.dark-theme:focus {
  outline: none;
  /* Removes the default focus outline */
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
  /* Changes shadow to a lighter color on focus */
}

.dialog_1024x768 {
  width: 1024px;
  height: 768px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: auto;
  scrollbar-darkshadow-color: #000000;
  scrollbar-base-color: #222222;
  scrollbar-face-color: transparent;
  scrollbar-track-color: transparent;
}

.dialog_fullscreen {
  width: 100vpw;
  height: 100vph;
  overflow-y: hidden;
  overflow-x: hidden;
}

.tablebody_scrollable {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: auto;
  scrollbar-darkshadow-color: #000000;
  scrollbar-base-color: #222222;
  scrollbar-face-color: transparent;
  scrollbar-track-color: transparent;
}

.directorybreadcrumbs {
  display: block;
  justify-content: left;
  width: 100%;
  align-items: last baseline;
  background-color: transparent;
  font-size: 1.0em;
  font-weight: bold;
  border-style: none;
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 1em;
  height: 2em;
  border-radius: 4px;
}

.scrollable-table {
  display: block;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: auto;
  scrollbar-darkshadow-color: #000000;
  scrollbar-base-color: #222222;
  scrollbar-face-color: transparent;
  scrollbar-track-color: transparent;
}

.scrollable-table_th {
  /* Make sure thead is above tbody when scrolling */
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #222222;
  font-size: 1.0em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  height: 1.5em;
}

.scrollable-table_tr>* {
  font-size: 1.0em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  height: 1.5em;
}

.scrollable-table_tr_selected {
  background-color: navy;
  color: white;
  /* Ensures text is readable on the navy background */
}

.scrollable-table_tr_selected>* {
  font-size: 1.0em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  height: 1.5em;
}

.dialog_640x768 {
  width: 640px;
  height: 768px;
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-width: 0;
  scrollbar-darkshadow-color: #000000;
  scrollbar-base-color: #222222;
}

.td_icon,
.th_icon {
  white-space: nowrap;
  padding: 4px;
  width: auto;
}

.td_box {
  padding: 0;
  box-sizing: border-box;
}

.td_button {
  width: 100%;
  /* Optional: Remove border, padding, and margin for the button to fit exactly */
  border: none;
  padding: 0;
  margin: 0;
}

/* CSS */
.hover-text {
  color: whitesmoke;
  /* Initial text color */
  transition: color 0.3s ease;
  /* Optional: smooth transition */
}

.hover-text:hover {
  color: cadetblue;
  /* Text color on hover */
}

.stackcenter {
  width: 100%;
  display: flex;
  justify-content: center;
  /* Center items horizontally */
  align-items: center;
  /* Center items vertically */
  border-style: none;
}

.table-assistant {
  display: table;
  /* Use table layout */
  border-collapse: collapse;
  /* Collapse borders between cells */
  border-radius: 8px;
  /* Rounded corners */
  border-width: 1px;
  /* Add a border around the table */
  padding: 10px;
  /* Apply padding to the table */
}

.table-assistant td {
  max-width: 300px;
  /* Set the maximum width of the column */
  padding: 10px;
  /* Apply padding inside each cell */
  word-wrap: break-word;
  /* Ensure long words can wrap and do not overflow */
  white-space: normal;
  /* Override the default nowrap of table cells if necessary */
}

.login {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Center items horizontally */
  align-items: flex-end;
  text-align: center;
  border-radius: 0px;
  border-style: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.navbarStyle {
  padding: .5rem 1rem !important
}

.button-dark-theme {
  background-color: #333;
  /* Dark background */
  color: white;
  /* White text */
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  /* Smooth transition for background color */
}

.button-dark-theme:hover {
  background-color: #787878;
  /* Lighter background on hover */
}

.blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  /* Semi-translucent white */
  backdrop-filter: blur(10px);
  /* Adjust the px value to increase/decrease the blur effect */
}

.avatar {
  width: 64px;
  /* Set the desired width */
  height: 64px;
  /* Set the desired height */
  border-radius: 50%;
  /* This creates the circular shape */
  object-fit: cover;
  /* Ensures the image covers the space without stretching */
}

.flex-container {
  display: flex;
  justify-content: center;
  /* Align horizontally */
  align-items: center;
  /* Align vertically */
  flex-direction: column;
  /* Stack items vertically */
}

/* Dark theme styles for <a> links */
a {
  color: #FFFFFF;
  /* Bright white text */
  text-decoration: underline;
  text-decoration-color: #808080;
  /* Gray underline */
  transition: color 0.3s ease, text-decoration-color 0.3s ease;
  /* Smooth transition for color and underline color */
}

a:hover,
a:focus {
  color: #ADD8E6;
  /* Light blue on hover/focus */
  text-decoration-color: #ADD8E6;
  /* Matching underline color on hover/focus */
}

.width_100 {
  width: 100%;
}

.width_200px {
  width: 200px;
}

.red-close-button:hover,
.red-close-button:active {
  background-color: darkred !important;
}

.floating-avatar {
  position: fixed; /* Use fixed positioning to keep the layer in place while scrolling */
  top: -200px;     /* Align the top edge of the element with the viewport */
  left: 0;         /* Align the left edge of the element with the viewport */
  height: 60vph;   /* Set the height of the element to 100% of the viewport height */
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent black background */
  margin: 0;
  padding: 0;
  z-index: 500;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out; /* Animation setup */
}

.floating-avatar-canvas {
  position: fixed; /* Use fixed positioning to keep the layer in place while scrolling */
  top: -200px;     /* Align the top edge of the element with the viewport */
  left: 0;         /* Align the left edge of the element with the viewport */
  height: 60vph;   /* Set the height of the element to 100% of the viewport height */
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent black background */
  margin: 0;
  padding: 0;
  z-index: 500;
}